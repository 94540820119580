import { Await, Form, Link, useFetcher, useNavigation } from '@remix-run/react';
import type { ArticleObject } from 'intercom-client';
import React from 'react';
import {
  ActivityCard,
  ActivityPlaceholder,
  ActivityPlaceholderAction,
  ActivityPlaceholderTitle,
} from '~/components/ActivityCard';
import { AppleStore } from '~/components/AppleStore';
import { AwaitError } from '~/components/AwaitError';
import { Faq, FaqItem } from '~/components/Faq';
import { GooglePlayStore } from '~/components/GooglePlayStore';
import { Calendar } from '~/components/drawings/Calendar';
import { Cocktails } from '~/components/drawings/Cocktails';
import { Flowers } from '~/components/drawings/Flowers';
import { Gift } from '~/components/drawings/Gift';
import { House } from '~/components/drawings/House';
import { Phone } from '~/components/drawings/Phone';
import { Play } from '~/components/icons/Play';
import { Avatar, AvatarImage } from '~/components/ui/Avatar';
import { Button, buttonVariants } from '~/components/ui/Button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '~/components/ui/Card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/ui/Carousel';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/Form';
import { Image } from '~/components/ui/Image';
import { Input } from '~/components/ui/Input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui/Select';
import { Skeleton } from '~/components/ui/Skeleton';
import {
  HeadingM,
  HeadingS,
  HeadingXL,
  HeadingXXL,
  headingVariants,
  textVariants,
} from '~/components/ui/Typography';
import { BLOG_LINK, HOUSESHARING_LINK } from '~/constants';
import type { EmailValidity } from '~/generated/server';
import { useTranslation } from '~/i18n';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { BlogPosts } from '../../components/BlogPosts';
import {
  Section,
  SectionContent,
  SectionDescription,
  SectionHeader,
  SectionTitle,
} from '../../components/Section';
import type { loader } from './route';

type Loader = Awaited<ReturnType<typeof loader>>;

interface Props {
  data: Extract<Loader, { viewer: undefined }>;
}

export default function PublicContent({ data }: Props) {
  const { t } = useTranslation('home');
  const navigation = useNavigation();
  const emailChecker = useFetcher<{
    email: string;
    emailValidity: EmailValidity;
  }>();
  const isEmailAlreadyTaken = emailChecker.data?.emailValidity === 'ALREADY_TAKEN';

  const faq = data.faq as ArticleObject[];

  return (
    <main className="pt-2">
      <section className="flex flex-col">
        <div className="relative mx-auto grid w-full max-w-screen-2xl place-content-center place-items-center md:place-items-start gap-y-10 px-4 py-10 text-center md:grid-cols-2 md:grid-rows-[auto,_auto] md:py-20 md:pl-20 md:text-left">
          <div className="max-w-[500px] space-y-6">
            <HeadingXXL className="hidden md:block">{t('hero-title')}</HeadingXXL>
            <HeadingXL className="md:hidden">{t('hero-title')}</HeadingXL>
            <p
              className={textVariants({
                size: 'xl',
                className: 'text-balance font-medium text-subdued-foreground',
              })}
            >
              {t('hero-description')}
            </p>
          </div>

          <div className="row-span-2 text-left">
            <Form method="post" action="?index&/register" className="relative w-full max-w-[365px]">
              <Card className="w-full">
                <CardHeader>
                  <CardTitle className={headingVariants({ size: 'm' })} asChild>
                    <span>{t('hero-signup-create-account')}</span>
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <FormItem>
                    <FormField>
                      <FormLabel>{t('hero-signup-firstname')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('hero-signup-firstname-placeholder')}
                          name="firstName"
                          required
                        />
                      </FormControl>
                    </FormField>
                    <FormMessage />
                  </FormItem>

                  <FormItem>
                    <FormField>
                      <FormLabel>{t('hero-signup-lastname')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('hero-signup-lastname-placeholder')}
                          name="lastName"
                          required
                        />
                      </FormControl>
                    </FormField>
                    <FormMessage />
                  </FormItem>

                  <FormItem>
                    <FormField>
                      <FormLabel>{t('hero-signup-email')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('hero-signup-email-placeholder')}
                          type="email"
                          name="email"
                          onChange={(e) => {
                            if (e.currentTarget.validity.valid) {
                              emailChecker.submit(
                                {
                                  email: e.currentTarget.value,
                                },
                                {
                                  method: 'POST',
                                  action: '?index&/validateEmail',
                                },
                              );
                            }
                          }}
                          required
                        />
                      </FormControl>
                    </FormField>
                    <FormMessage />
                  </FormItem>

                  {isEmailAlreadyTaken ? (
                    <div className="space-y-3 rounded-2xl border p-3">
                      <p
                        className={textVariants({
                          size: 's',
                          class: 'font-medium text-error',
                        })}
                      >
                        {t('email-already-taken-error')}
                      </p>

                      <Link
                        to={'/login?email=' + encodeURIComponent(String(emailChecker.data?.email))}
                        className={buttonVariants({ class: 'w-full' })}
                        prefetch="intent"
                      >
                        {t('email-already-taken')}
                      </Link>
                    </div>
                  ) : null}

                  <React.Suspense
                    fallback={
                      <FormItem>
                        <FormField className="relative">
                          <FormLabel>{t('hero-signup-area')}</FormLabel>
                          <FormControl>
                            <Select required>
                              <SelectTrigger aria-label={t('hero-signup-area-placeholder')}>
                                <SelectValue placeholder={t('hero-signup-area-placeholder')} />
                              </SelectTrigger>
                              <SelectContent>.</SelectContent>
                            </Select>
                          </FormControl>
                        </FormField>
                      </FormItem>
                    }
                  >
                    <Await resolve={data.areas} errorElement={<AwaitError />}>
                      {({ areas }) => (
                        <FormItem>
                          <FormField className="relative">
                            <FormLabel>{t('hero-signup-area')}</FormLabel>

                            <FormControl>
                              <Select name="area" required>
                                <SelectTrigger aria-label={t('hero-signup-area-placeholder')}>
                                  <SelectValue placeholder={t('hero-signup-area-placeholder')} />
                                </SelectTrigger>
                                <SelectContent>
                                  {areas.map((area) => (
                                    <SelectItem value={area.value} key={area.value}>
                                      {area.displayName}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </FormControl>
                          </FormField>
                          <FormMessage />
                        </FormItem>
                      )}
                    </Await>
                  </React.Suspense>
                </CardContent>
                <CardFooter>
                  <Button
                    className="w-full"
                    loading={
                      navigation.formAction?.endsWith('register') &&
                      navigation.state === 'submitting'
                    }
                  >
                    {t('hero-signup-create-account-button')}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </div>

          <div className="flex max-w-[500px] flex-col items-center gap-y-6 md:items-start">
            <HeadingM>{t('hero-download-app')}</HeadingM>

            <div className="flex items-center gap-4">
              <AppleStore className="h-[50px] md:h-14" />

              <GooglePlayStore className="h-[50px] md:h-14" />
            </div>
          </div>

          <div className="absolute inset-y-0 right-6 z-[-1] hidden !w-[370px] overflow-hidden rounded-l-lg md:block 2xl:!w-[450px] 2xl:rounded-lg">
            <Image
              alt="Un groupe de seniors souriants réunis autour d'une table, partageant des rires et des boissons dans un cadre chaleureux."
              src="/images/home-cover.jpeg"
              className="h-full object-cover object-[85%_center]"
              width={665}
              height={450}
              quality={100}
              loading="eager"
            />
          </div>
        </div>

        <Image
          alt="Un groupe de seniors souriants réunis autour d'une table, partageant des rires et des boissons dans un cadre chaleureux."
          src="/images/home-cover.jpeg"
          className="h-[428px] object-cover md:hidden"
          height={428}
          width={800}
        />

        <div className="bg-background md:mt-4 md:border-y-2 md:bg-transparent">
          <div className="mx-auto flex max-w-screen-2xl flex-wrap items-center justify-center gap-x-4 gap-y-6 px-6 py-10 lg:h-28 md:gap-x-[2.5vw]">
            {[
              { src: 'le-monde.png', alt: 'Le Monde' },
              { src: 'M6.png', alt: 'M6' },
              { src: 'tf1.png', alt: 'TF1' },
              { src: 'les-echos.png', alt: 'Les Echos' },
              { src: 'france3.png', alt: 'France 3' },
              { src: 'femme-actuelle.png', alt: 'Femme Actuelle' },
            ].map((clipping) => (
              <Image
                key={clipping.src}
                src={`/clippings/${clipping.src}`}
                height={36}
                width={150}
                className="h-9 w-auto object-contain"
                alt={`Logo ${clipping.alt}`}
              />
            ))}
          </div>
        </div>
      </section>

      <Section>
        <SectionContent>
          <SectionHeader>
            <SectionTitle className="mx-auto text-center md:max-w-xl">
              {t('live-your-best-life-with-colette')}
            </SectionTitle>
          </SectionHeader>

          <div className="mb-10 max-w-[600px] space-y-4 text-center md:mb-14 md:text-left">
            <HeadingXL>{t('activity-and-conference')}</HeadingXL>
            <p
              className={textVariants({
                size: 'xl',
                className: 'text-balance font-medium text-subdued-foreground',
              })}
            >
              {t('activity-and-conference-description')}
            </p>
          </div>

          <div className="-ml-4 md:ml-0">
            <React.Suspense
              fallback={
                <div className="mx-auto flex w-[calc(100vw_-_32px)] gap-4 overflow-x-auto md:grid md:w-auto md:grid-cols-4 md:gap-6">
                  <Skeleton className="h-[356px] flex-[0_0_290px] rounded-lg md:h-[392px] md:flex-auto" />
                  <Skeleton className="h-[356px] flex-[0_0_290px] rounded-lg md:h-[392px] md:flex-auto" />
                  <Skeleton className="hidden h-[356px] rounded-lg md:flex md:h-[392px]" />
                  <Skeleton className="hidden h-[356px] rounded-lg md:flex md:h-[392px]" />
                </div>
              }
            >
              <Await resolve={data.activities} errorElement={<AwaitError />}>
                {({ publicActivities }) => (
                  <Carousel className="w-screen md:w-auto md:max-w-7xl" opts={{ dragFree: true }}>
                    <CarouselContent className="pb-1 first:pl-4 md:first:pl-0">
                      {connectionResultToNodeArray(publicActivities).map((activity) => (
                        <CarouselItem
                          key={activity.id}
                          className="flex-[0_0_290px] sm:basis-1/2 md:basis-1/3 lg:basis-1/4"
                        >
                          <ActivityCard activity={activity} className="h-full" />
                        </CarouselItem>
                      ))}

                      <CarouselItem className="flex-[0_0_290px] sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                        <Link to="/activites-senior" prefetch="intent">
                          <ActivityPlaceholder className="flex flex-col items-center justify-center gap-6 bg-surface-blue p-4">
                            <Calendar className="mx-auto aspect-square size-32" />
                            <ActivityPlaceholderTitle className="max-w-[60%] text-center">
                              {t('and-much-more')}
                            </ActivityPlaceholderTitle>
                            <ActivityPlaceholderAction>
                              {t('see-upcoming-activities')}
                            </ActivityPlaceholderAction>
                          </ActivityPlaceholder>
                        </Link>
                      </CarouselItem>
                    </CarouselContent>
                  </Carousel>
                )}
              </Await>
            </React.Suspense>
          </div>
        </SectionContent>
      </Section>

      <Section className="bg-surface-blue">
        <SectionContent>
          <SectionHeader>
            <div className="flex items-center justify-between">
              <House className="size-14" />
              <Link
                to={HOUSESHARING_LINK}
                className={buttonVariants({ class: 'hidden lg:block', variant: 'tertiary' })}
              >
                {t('house-sharing-service-button')}
              </Link>
            </div>
            <SectionTitle className={headingVariants({ size: 'xl' })}>
              {t('house-sharing-service')}
            </SectionTitle>
            <SectionDescription>{t('house-sharing-service-description')}</SectionDescription>

            <Link
              to="/cohabitation-intergenerationnelle"
              target="_blank"
              className={buttonVariants({ class: 'w-full lg:hidden', variant: 'tertiary' })}
            >
              {t('house-sharing-service-button')}
            </Link>
          </SectionHeader>

          <div className="grid lg:grid-cols-2 gap-6 mb-6">
            <Card className="bg-blue">
              <CardHeader>
                <CardTitle>{t('house-sharing-available-room')}</CardTitle>
                <CardDescription>
                  {t('house-sharing-available-room-description')}{' '}
                  <span className="mt-4">
                    {t('house-sharing-insurance')}{' '}
                    <Image
                      src="/images/axa-logo.webp"
                      height={32}
                      width={32}
                      className="size-8 inline ml-4"
                      alt="Axa"
                    />
                  </span>
                </CardDescription>
              </CardHeader>
              <CardFooter>
                <Link
                  to="/hotes"
                  prefetch="intent"
                  className={buttonVariants({ variant: 'tertiary', class: 'w-full md:w-auto' })}
                >
                  {t('house-sharing-rent-a-room-button')}
                </Link>
              </CardFooter>
            </Card>

            <div>
              <Image
                src="/images/room.jpeg"
                alt="Un salon lumineux et spacieux."
                className="h-full w-full object-cover rounded-lg"
                width={630}
                height={425}
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-3 gap-6">
            <Card className="bg-blue">
              <CardHeader>
                <CardTitle>{t('house-sharing-looking-for-a-room')}</CardTitle>
                <CardDescription>
                  {t('house-sharing-looking-for-a-room-description')}
                </CardDescription>
              </CardHeader>
              <CardFooter>
                <Link
                  to="/cohabitants"
                  prefetch="intent"
                  className={buttonVariants({ variant: 'tertiary', class: 'w-full md:w-auto' })}
                >
                  {t('house-sharing-looking-for-a-room-button')}
                </Link>
              </CardFooter>
            </Card>

            <Card className="bg-blue">
              <CardHeader>
                <CardTitle>{t('they-are-fans-of-colette-room')}</CardTitle>
                <CardDescription>{t('they-are-fans-of-colette-room-description')}</CardDescription>
              </CardHeader>
              <CardFooter className="-space-x-3">
                {['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'].map((avatar) => (
                  <Avatar className="border-[3px] border-surface size-14" key={avatar}>
                    <AvatarImage
                      src={`/avatars/${avatar}`}
                      className="rounded-full object-center"
                      height={64}
                      width={64}
                    />
                  </Avatar>
                ))}
              </CardFooter>
            </Card>

            <div className="relative">
              <Link to="https://www.youtube.com/watch?v=99y4L4RPgy8" target="_blank">
                <div className="absolute inset-0 grid place-content-center place-items-center group">
                  <div className="rounded-full bg-foreground p-2 group-hover:bg-subdued-foreground transition-colors">
                    <Play className="size-14 text-background" />
                  </div>
                </div>
                <Image
                  src="/images/chantal-flowers.jpg"
                  alt="Un groupe de colocataires souriants."
                  className="h-full w-full object-cover object-center rounded-lg"
                  width={630}
                  height={425}
                />
              </Link>
            </div>
          </div>
        </SectionContent>
      </Section>

      <Section className="mt-20">
        <SectionContent className="py-0 md:py-0">
          <div className="flex flex-col items-center justify-center gap-6 rounded-lg bg-surface px-10 py-8 md:flex-row md:p-16">
            <div className="flex flex-col items-center gap-4 text-center">
              <Cocktails className="size-[104px]" />
              <HeadingS className="max-w-xs text-balance" asChild>
                <span>{t('a-community-of-70-000-members')}</span>
              </HeadingS>
            </div>

            <div className="flex flex-col items-center gap-4 text-center">
              <Gift className="size-[104px]" />
              <HeadingS className="max-w-xs text-balance" asChild>
                <span>{t('free-access-no-commitment')}</span>
              </HeadingS>
            </div>

            <div className="flex flex-col items-center gap-4 text-center">
              <Flowers className="size-[104px]" />
              <HeadingS className="max-w-xs text-balance" asChild>
                <span>{t('services-to-improve-your-daily-life')}</span>
              </HeadingS>
            </div>

            <div className="flex flex-col items-center gap-4 text-center">
              <Phone className="size-[104px]" />
              <HeadingS className="max-w-xs text-balance" asChild>
                <span>{t('a-reliable-team-listening-to-you')}</span>
              </HeadingS>
            </div>
          </div>
        </SectionContent>
      </Section>

      <Section id="how-it-works">
        <SectionContent className="text-center md:text-left">
          <Card className="overflow-hidden md:grid md:grid-cols-2">
            <Image
              src="/images/how-to-cover.jpeg"
              alt="Une femme senior utilisant l'application Colette sur son téléphone portable."
              className="h-[448px] object-cover object-[70%_center] md:order-2 md:h-full"
              height={900}
              width={700}
            />
            <div className="p-6">
              <CardHeader className="md:pb-16">
                <HeadingXXL asChild>
                  <CardTitle>{t('how-it-works')}</CardTitle>
                </HeadingXXL>
              </CardHeader>
              <CardContent>
                <ul className="flex flex-col items-center gap-y-6 md:items-start md:gap-y-10">
                  <li className="grid items-center justify-items-center gap-y-3 md:grid-cols-[auto_1fr] md:items-start md:justify-items-start md:gap-x-5">
                    <svg
                      className="md:row-span-2"
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.704 6.1385C-0.362897 15.7219 2.34247 33.7065 17.4095 40.6292C21.2211 42.5955 25.5669 43.5268 29.8147 42.6903C39.2504 41.5615 46.7634 33.5987 44.0025 23.8502C42.1432 17.4356 38.1161 11.426 32.9376 7.28789C26.8137 3.10131 21.7959 3.13305 13.7014 6.1396L13.704 6.1385Z"
                        fill="#193035"
                      />
                      <path
                        d="M21.7285 29.3848C21.7285 29.0527 22.0117 28.8477 22.5879 28.75C22.959 28.7012 23.2422 28.6328 23.418 28.5449C23.5547 28.4766 23.6523 28.3984 23.7109 28.3203C23.7598 28.2422 23.7988 28.0859 23.8281 27.8711C23.8477 27.6367 23.8574 27.168 23.8672 26.4453C23.877 25.7324 23.8867 24.1406 23.8867 21.6797C23.8867 21.4844 23.8867 21.2402 23.8965 20.9473C23.8965 20.6543 23.9062 20.459 23.9062 20.3613C23.9062 19.8828 23.8574 19.5703 23.7793 19.4336C23.7012 19.2969 23.5352 19.2188 23.2812 19.2188C23.1836 19.2188 23.0371 19.2285 22.8223 19.248C22.6074 19.2676 22.4414 19.2773 22.3242 19.2773C22.1191 19.2773 21.9727 19.248 21.875 19.1797C21.7773 19.1113 21.7285 19.0039 21.7285 18.8574C21.7285 18.6621 21.8262 18.4863 22.0312 18.3301C22.2266 18.1738 22.5781 18.0078 23.0859 17.8223C23.6914 17.6074 24.2676 17.4316 24.8242 17.3047C25.3809 17.1875 25.8203 17.1191 26.1426 17.1191C26.416 17.1191 26.6309 17.1777 26.7773 17.2754C26.9238 17.3828 27.002 17.5293 27.002 17.7246C27.002 17.9492 26.8652 18.1445 26.6016 18.3203C26.5234 18.3789 26.4746 18.4082 26.4648 18.418C26.377 18.5254 26.3184 18.8477 26.2891 19.3848C26.2598 19.9219 26.25 21.2109 26.25 23.2422C26.25 24.2871 26.25 25.2344 26.2598 26.0742C26.2695 26.9238 26.2891 27.5098 26.3086 27.8418C26.3184 28.0762 26.3574 28.2324 26.416 28.3203C26.4746 28.4082 26.582 28.4863 26.748 28.5645C26.9141 28.6426 27.1777 28.7012 27.5391 28.75C28.1055 28.8379 28.3887 29.0332 28.3887 29.3359C28.3887 29.6191 28.2227 29.8145 27.8906 29.9121C27.5586 30.0098 26.7285 30.0586 25.3809 30.0586H25.0488C23.5156 30.0586 22.5781 30.0195 22.2363 29.9219C21.8945 29.8242 21.7285 29.6484 21.7285 29.3848Z"
                        fill="white"
                      />
                    </svg>
                    <HeadingM className="md:self-end">{t('create-your-account')}</HeadingM>
                    <span
                      className={textVariants({
                        size: 'xl',
                        class: 'font-medium text-subdued-foreground md:col-span-2 md:col-start-2',
                      })}
                    >
                      {t('create-your-account-description')}
                    </span>
                  </li>

                  <li className="grid items-center justify-items-center gap-y-3 md:grid-cols-[auto_1fr] md:items-start md:justify-items-start md:gap-x-5">
                    <svg
                      className="md:row-span-2"
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                    >
                      <path
                        d="M13.704 6.1385C-0.362897 15.7219 2.34247 33.7065 17.4095 40.6292C21.2211 42.5955 25.5669 43.5268 29.8147 42.6903C39.2504 41.5615 46.7634 33.5987 44.0025 23.8502C42.1432 17.4356 38.1161 11.426 32.9376 7.28789C26.8137 3.10131 21.7959 3.13305 13.7014 6.1396L13.704 6.1385Z"
                        fill="#193035"
                      />
                      <path
                        d="M20.5469 30C20.1465 30 19.8926 29.9707 19.7852 29.9023C19.6777 29.8438 19.6289 29.7168 19.6289 29.5215C19.6289 29.209 20.0781 28.6426 20.9766 27.8125C21.4941 27.3438 21.9141 26.9531 22.2266 26.6406C23.9941 24.9023 25.1074 23.6621 25.5664 22.9395C26.0156 22.2168 26.25 21.5137 26.25 20.8203C26.25 20.1855 26.0645 19.6777 25.7031 19.2871C25.3418 18.8965 24.873 18.7012 24.2871 18.7012C23.877 18.7012 23.4961 18.7891 23.125 18.9648C22.7539 19.1406 22.373 19.4238 21.9922 19.7949C21.8848 19.8926 21.748 20.0391 21.582 20.2344C21.2305 20.6445 20.918 20.8398 20.6641 20.8398C20.4688 20.8398 20.3223 20.791 20.2246 20.6836C20.1172 20.5859 20.0684 20.4395 20.0684 20.2441C20.0684 20.0488 20.1172 19.8438 20.2344 19.6094C20.3418 19.3848 20.5078 19.1504 20.7227 18.916C21.2695 18.3301 21.9141 17.8711 22.6465 17.5586C23.3789 17.2461 24.1602 17.0801 24.9902 17.0801C26.2207 17.0801 27.207 17.4023 27.9688 18.0371C28.7207 18.6816 29.1016 19.5117 29.1016 20.5371C29.1016 21.1621 28.9355 21.7773 28.623 22.3828C28.3008 22.9883 27.793 23.6328 27.0898 24.3164C26.7578 24.6387 26.1816 25.1562 25.3613 25.8691C24.5312 26.5918 23.8867 27.168 23.4277 27.5977C23.3887 27.6562 23.3691 27.6953 23.3496 27.7344C23.3301 27.7734 23.3301 27.8027 23.3301 27.8223C23.3301 27.8613 23.3496 27.8906 23.3984 27.9102C23.4473 27.9297 23.5254 27.9395 23.623 27.9395H26.8262C27.1973 27.9395 27.4707 27.9102 27.6562 27.8516C27.8418 27.793 27.9883 27.6953 28.1055 27.5586C28.2031 27.4316 28.3301 27.2461 28.4766 26.9922C28.75 26.5234 29.0332 26.2793 29.3066 26.2793C29.4629 26.2793 29.5898 26.3379 29.6875 26.4551C29.7852 26.5723 29.8438 26.7285 29.8438 26.9043C29.8438 27.2363 29.7949 27.5781 29.7168 27.9395C29.6289 28.3105 29.502 28.7109 29.3262 29.1602C29.1016 29.707 28.8965 30.0781 28.7207 30.2832C28.5352 30.498 28.3398 30.5957 28.125 30.5957C27.959 30.5957 27.8418 30.5469 27.7637 30.4492C27.6855 30.3516 27.6465 30.2051 27.6465 30H20.5469Z"
                        fill="white"
                      />
                    </svg>
                    <HeadingM className="md:self-end">{t('unlimited-services')}</HeadingM>
                    <span
                      className={textVariants({
                        size: 'xl',
                        class: 'font-medium text-subdued-foreground',
                      })}
                    >
                      {t('unlimited-services-description')}
                    </span>
                  </li>

                  <li className="grid items-center justify-items-center gap-y-3 md:grid-cols-[auto_1fr] md:items-start md:justify-items-start md:gap-x-5">
                    <svg
                      className="md:row-span-2"
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                    >
                      <path
                        d="M13.704 6.1385C-0.362897 15.7219 2.34247 33.7065 17.4095 40.6292C21.2211 42.5955 25.5669 43.5268 29.8147 42.6903C39.2504 41.5615 46.7634 33.5987 44.0025 23.8502C42.1432 17.4356 38.1161 11.426 32.9376 7.28789C26.8137 3.10131 21.7959 3.13305 13.7014 6.1396L13.704 6.1385Z"
                        fill="#193035"
                      />
                      <path
                        d="M26.4258 23.584C27.373 23.7402 28.1055 24.1211 28.623 24.7461C29.1406 25.3711 29.4043 26.1816 29.4043 27.1777C29.4043 28.623 28.8574 29.7656 27.7734 30.625C26.6895 31.4844 25.2344 31.9043 23.4082 31.9043C22.2363 31.9043 21.3086 31.7969 20.6348 31.582C19.9609 31.3672 19.6289 31.0742 19.6289 30.7031C19.6289 30.5078 19.6973 30.3418 19.8438 30.2051C19.9902 30.0684 20.166 30 20.3809 30C20.4004 30 20.6836 30.0781 21.25 30.2148C21.8066 30.3516 22.3633 30.4199 22.9297 30.4199C24.1797 30.4199 25.1074 30.1758 25.7227 29.6777C26.3379 29.1797 26.6504 28.418 26.6504 27.4023C26.6504 26.6211 26.416 25.9961 25.957 25.5273C25.4883 25.0586 24.873 24.8242 24.1016 24.8242C23.8867 24.8242 23.623 24.8535 23.3105 24.9121C22.9883 24.9707 22.7734 25 22.666 25C22.4707 25 22.334 24.9512 22.2559 24.8535C22.168 24.7559 22.1289 24.5898 22.1289 24.3555C22.1289 24.1211 22.373 23.9062 22.8613 23.7109C23.0176 23.6523 23.1445 23.6035 23.2422 23.5645C24.082 23.2031 24.6973 22.793 25.0879 22.3438C25.4785 21.8945 25.6836 21.3672 25.6836 20.7617C25.6836 20.1172 25.498 19.5996 25.1465 19.2188C24.7949 18.8379 24.3066 18.6426 23.7012 18.6426C23.1934 18.6426 22.5879 18.8184 21.9043 19.1699C21.2109 19.5215 20.7812 19.6973 20.625 19.6973C20.4688 19.6973 20.3418 19.6387 20.2246 19.5215C20.1074 19.4043 20.0488 19.2578 20.0488 19.1016C20.0488 18.9648 20.0977 18.8184 20.2051 18.6816C20.3125 18.5449 20.4688 18.418 20.6836 18.2812C21.3281 17.8711 21.9824 17.5684 22.6562 17.3633C23.3203 17.168 24.0137 17.0605 24.7266 17.0605C25.8398 17.0605 26.7383 17.373 27.4121 17.998C28.0859 18.623 28.4277 19.4531 28.4277 20.4785C28.4277 21.1035 28.252 21.6797 27.9102 22.207C27.5684 22.7441 27.0703 23.2031 26.4258 23.584Z"
                        fill="white"
                      />
                    </svg>

                    <HeadingM className="md:self-end">{t('download-app')}</HeadingM>
                    <span
                      className={textVariants({
                        size: 'xl',
                        class: 'font-medium text-subdued-foreground',
                      })}
                    >
                      {t('download-app-description')}
                    </span>
                  </li>
                </ul>

                <div className="mt-6 flex items-center justify-center gap-4">
                  <AppleStore className="h-[44px] md:h-14" />

                  <GooglePlayStore className="h-[44px] md:h-14" />
                </div>
              </CardContent>
            </div>
          </Card>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent className="pt-0 lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-10">
          <SectionHeader className="flex-1 text-center md:mb-0 lg:text-left">
            <SectionTitle>{t('live-colette-adventure')}</SectionTitle>
            <SectionDescription className="lg:max-w-md">
              {t('colette-development')}
            </SectionDescription>
          </SectionHeader>

          <div className="-ml-4 md:ml-0 lg:order-3 lg:col-span-full lg:mt-10">
            <React.Suspense
              fallback={
                <div className="col-span-full flex w-screen gap-4 overflow-x-auto lg:w-auto lg:max-w-7xl">
                  <Skeleton className="h-72 flex-[0_0_172px] rounded-lg sm:basis-1/4 lg:basis-1/6" />
                  <Skeleton className="h-72 flex-[0_0_172px] rounded-lg sm:basis-1/4 lg:basis-1/6" />
                  <Skeleton className="h-72 flex-[0_0_172px] rounded-lg sm:basis-1/4 lg:basis-1/6" />
                  <Skeleton className="h-72 flex-[0_0_172px] rounded-lg sm:basis-1/4 lg:basis-1/6" />
                  <Skeleton className="h-72 flex-[0_0_172px] rounded-lg sm:basis-1/4 lg:basis-1/6" />
                  <Skeleton className="h-72 flex-[0_0_172px] rounded-lg sm:basis-1/4 lg:basis-1/6" />
                  <Skeleton className="h-72 flex-[0_0_172px] rounded-lg sm:basis-1/4 lg:basis-1/6" />
                  <Skeleton className="h-72 flex-[0_0_172px] rounded-lg sm:basis-1/4 lg:basis-1/6" />
                </div>
              }
            >
              <Await resolve={data.areas} errorElement={<AwaitError />}>
                {({ areas }) => (
                  <Carousel className="w-screen lg:w-auto lg:max-w-7xl" opts={{ dragFree: true }}>
                    <CarouselContent className="pb-1 first:pl-4 md:first:pl-0">
                      {areas.map((area) => (
                        <CarouselItem
                          key={area.value}
                          className="flex-[0_0_172px] sm:basis-1/4 lg:basis-1/6"
                        >
                          <Link
                            to={`/activites-senior?area=${area.value}`}
                            className="flex h-72 flex-col overflow-hidden rounded-lg bg-white shadow-[0px_4px_0px_0px_#1930350A] lg:w-full"
                            prefetch="intent"
                          >
                            <Image
                              src={area.pictureUrl}
                              alt={
                                'Photo représentant ' +
                                area.displayName.replace(/\(bientôt disponible\)/gi, '')
                              }
                              className="h-[218px] object-cover"
                              height={218}
                              width={300}
                            />
                            <HeadingS
                              className="flex flex-1 items-center justify-center text-center"
                              asChild
                            >
                              <span>
                                {area.displayName
                                  .replace('et sa région', '')
                                  .replace(/\(bientôt disponible\)/gi, '')
                                  .trim()}
                              </span>
                            </HeadingS>
                          </Link>
                        </CarouselItem>
                      ))}
                    </CarouselContent>
                    <div className="mt-10 hidden items-center justify-center gap-4 lg:flex">
                      <CarouselPrevious
                        className="relative left-auto top-auto size-12 translate-y-0 rounded-lg shadow-[0px_4px_0px_0px_#1930350A] [&_svg]:size-5"
                        variant="tertiary"
                        size="icon"
                      />
                      <CarouselNext
                        className="relative right-auto top-auto size-12 translate-y-0 rounded-lg shadow-[0px_4px_0px_0px_#1930350A] [&_svg]:size-5"
                        variant="tertiary"
                        size="icon"
                      />
                    </div>
                  </Carousel>
                )}
              </Await>
            </React.Suspense>
          </div>

          <Card className="mt-10 bg-secondary lg:mt-0">
            <CardHeader>
              <HeadingM asChild>
                <CardTitle className="text-center lg:text-left">
                  {t('request-store-opening')}
                </CardTitle>
              </HeadingM>
            </CardHeader>
            <CardContent>
              <Form
                className="grid sm:grid-cols-2 sm:gap-x-2"
                method="post"
                action="?index&/requestNewArea"
              >
                <FormItem className="mb-2">
                  <FormField>
                    <FormLabel>{t('email')}</FormLabel>
                    <FormControl>
                      <Input type="email" placeholder={t('your-email')} name="email" required />
                    </FormControl>
                  </FormField>
                  <FormMessage />
                </FormItem>

                <FormItem className="mb-6">
                  <FormField>
                    <FormLabel>{t('postal-code')}</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder={t('your-postal-code')}
                        name="postalCode"
                        required
                      />
                    </FormControl>
                  </FormField>
                  <FormMessage />
                </FormItem>

                <Button
                  className="w-full sm:col-span-full"
                  loading={
                    navigation.state === 'submitting' &&
                    navigation.formAction.endsWith('/requestNewArea')
                  }
                >
                  {t('send-request')}
                </Button>
              </Form>
            </CardContent>
          </Card>
        </SectionContent>
      </Section>

      <Section className="bg-surface-dark text-on">
        <SectionContent>
          <SectionHeader>
            <SectionTitle className="text-center">{t('blog-section-title')}</SectionTitle>
          </SectionHeader>
          <BlogPosts data={data} />

          <div className="mt-24 flex justify-center">
            <Link to={BLOG_LINK} className={buttonVariants({ variant: 'tertiary' })}>
              {t('blog-section-discover-more')}
            </Link>
          </div>
        </SectionContent>
      </Section>

      <Faq defaultValue="0">
        {faq.map((item, i) => (
          <FaqItem key={item.id} value={'' + i} title={item.title}>
            <div
              // biome-ignore lint/security/noDangerouslySetInnerHtml: Faq content is safe
              dangerouslySetInnerHTML={{ __html: item.body }}
              className="[&_ul]:list-disc [&_ul]:list-inside [&_ul_li_p]:inline [&_ul]:py-4"
            />
          </FaqItem>
        ))}
      </Faq>
    </main>
  );
}
