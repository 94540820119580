import { Link } from '@remix-run/react';
import dayjs from 'dayjs';
import type React from 'react';
import { ActivityCard } from '~/components/ActivityCard';
import { SubscriptionBanner } from '~/components/SubscriptionBanner';
import { buttonVariants } from '~/components/ui/Button';
import { HeadingL, HeadingM, HeadingXXL, textVariants } from '~/components/ui/Typography';
import { useTranslation } from '~/i18n';
import { cn } from '~/utils/cn';
import type { loader } from './route';

type Loader = Awaited<ReturnType<typeof loader>>;

interface Props {
  data: Extract<Loader, { blogPosts: undefined }>;
}

export default function PrivateContent({ data }: Props) {
  const { t } = useTranslation('home');

  return (
    <main>
      <SubscriptionBanner className="mx-auto max-w-screen-xl" />

      <Section>
        <SectionContent className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          <div>
            <p
              className={textVariants({
                size: 'xl',
                class: 'font-bold capitalize mb-2',
              })}
            >
              {dayjs().format('dddd D MMMM YYYY')}
            </p>
            <HeadingXXL>{t('welcome', { firstname: data.viewer.profile.firstName })}</HeadingXXL>
          </div>

          <Link to="/suggest-activity" className={buttonVariants()}>
            {t('suggest-an-activity')}
          </Link>
        </SectionContent>
      </Section>

      {data.myActivities?.length ? (
        <Section>
          <SectionContent>
            <SectionHeader>
              <SectionTitle>{t('my-activities')}</SectionTitle>
              <SectionExtra className="hidden lg:flex">
                <Link
                  prefetch="intent"
                  to="/my-activities"
                  className={buttonVariants({
                    variant: 'tertiary',
                    class: 'w-auto',
                  })}
                >
                  {t('all-my-activities')}
                </Link>
              </SectionExtra>
            </SectionHeader>

            <div className="grid grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))] gap-4 md:gap-6">
              {data.myActivities.map((activity) => (
                <ActivityCard key={activity.id} activity={activity} />
              ))}
            </div>

            <Link
              prefetch="intent"
              to="/my-activities"
              className={buttonVariants({
                variant: 'tertiary',
                class: 'w-full lg:hidden mt-6',
              })}
            >
              {t('all-my-activities')}
            </Link>
          </SectionContent>
        </Section>
      ) : (
        <Section>
          <SectionContent>
            <SectionHeader>
              <SectionTitle>{t('no-upcoming-activities')}</SectionTitle>
            </SectionHeader>

            <div className="space-y-8 rounded-2xl bg-surface-blue p-8">
              <HeadingM className="font-medium">{t('you-might-like')}</HeadingM>
              <div className="grid grid-cols-[repeat(auto-fill,_minmax(280px,_1fr))] gap-4 md:gap-6">
                {data.suggestedActivities.map((activity) => (
                  <ActivityCard key={activity.id} activity={activity} canRegister />
                ))}
              </div>
            </div>
          </SectionContent>
        </Section>
      )}

      <Section className="bg-surface">
        <SectionContent>
          <SectionHeader>
            <SectionTitle>{t('my-suggested-activities')}</SectionTitle>

            {data.mySuggestedActivities.length ? (
              <SectionExtra className="hidden lg:flex">
                <Link
                  prefetch="intent"
                  to="/my-suggested-activities"
                  className={buttonVariants({
                    variant: 'tertiary',
                  })}
                >
                  {t('all-my-suggested-activities')}
                </Link>
              </SectionExtra>
            ) : null}
          </SectionHeader>

          {data.mySuggestedActivities.length ? (
            <>
              <div className="grid grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))] gap-4 md:gap-6">
                {data.mySuggestedActivities.map((activity) => (
                  <ActivityCard key={activity.id} activity={activity} canRegister />
                ))}
              </div>

              <Link
                prefetch="intent"
                to="/my-suggested-activities"
                className={buttonVariants({
                  variant: 'tertiary',
                  class: 'w-full lg:hidden mt-6',
                })}
              >
                {t('all-my-suggested-activities')}
              </Link>
            </>
          ) : (
            <div className="flex flex-col gap-6 py-10 items-center">
              <p
                className={textVariants({
                  size: 'l',
                  class: 'text-subdued-foreground font-medium text-center max-w-4xl',
                })}
              >
                {t('suggest-an-activity-empty-state')}
              </p>
              <Link to="/suggest-activity" className={buttonVariants()}>
                {t('suggest-an-activity-button')}
              </Link>
            </div>
          )}
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <SectionHeader>
            <SectionTitle>{t('suggested-activities')}</SectionTitle>
          </SectionHeader>

          <div className="grid grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))] gap-4 md:gap-6">
            {data.suggestedActivities.map((activity) => (
              <ActivityCard key={activity.id} activity={activity} canRegister />
            ))}
          </div>

          <div className="text-center mt-8">
            <Link
              to="/activites-senior"
              className={buttonVariants({ class: 'w-full lg:w-auto' })}
              prefetch="intent"
            >
              {t('see-all-activities')}
            </Link>
          </div>
        </SectionContent>
      </Section>
    </main>
  );
}

function Section(props: React.ComponentProps<'section'>) {
  return <section {...props} />;
}

function SectionContent(props: React.ComponentProps<'div'>) {
  return (
    <div {...props} className={cn('mx-auto max-w-screen-xl px-4 xl:px-0 py-12', props.className)} />
  );
}

function SectionHeader(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'flex flex-col justify-between md:flex-row mb-6 gap-4 md:items-center',
        props.className,
      )}
    />
  );
}

function SectionTitle(props: React.ComponentProps<typeof HeadingL>) {
  return <HeadingL {...props} />;
}

function SectionExtra(props: React.ComponentProps<'div'>) {
  return <div {...props} className={cn('flex items-center gap-4', props.className)} />;
}
