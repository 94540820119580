export function Gift(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 105 104" fill="none" {...props}>
      <path
        d="M46.952 48.8799C45.912 48.5332 16.792 57.5465 16.4453 57.5465L46.952 70.3732L80.232 58.5865C69.4853 55.4665 47.784 49.1572 46.952 48.8799Z"
        fill="#325A50"
      />
      <path
        d="M16.4642 57.1001C16.0987 56.9779 15.718 57.2337 15.6838 57.6192C15.2726 62.623 14.8615 74.5392 14.4503 78.7492C14.3399 79.879 14.2524 81.1042 14.8577 82.0622C15.4553 83.005 16.5708 83.4515 17.6177 83.837C27.0245 87.3218 36.4313 90.8104 45.8382 94.2952C46.4206 88.902 46.7556 83.4782 46.8394 78.0507C46.847 77.6194 46.8432 68.5964 46.63 68.2185C46.3826 67.7872 45.9067 67.5544 45.4613 67.3445C38.6355 64.1612 23.5031 59.4055 16.4642 57.0925V57.1001Z"
        fill="#E2826B"
      />
      <path
        d="M80.7444 59.9053C80.7672 58.913 79.8193 58.1878 78.8714 58.4664L57.035 64.9054C54.1266 65.7642 51.2181 66.6192 48.3477 67.5924C47.769 67.7871 47.1599 68.0085 46.7716 68.4818C46.33 69.0199 46.2615 78.333 46.212 79.0277C45.9037 83.4667 45.5953 87.9056 45.2869 92.3446C45.2413 92.982 45.2298 93.7149 45.9912 94.0126C46.3262 94.1424 46.6993 94.1271 47.0457 94.0317C57.3586 91.2263 67.4697 87.669 77.2649 83.3941C78.129 83.0163 79.0236 82.6079 79.5947 81.856C80.2647 80.9743 80.3522 79.7911 80.4055 78.6842C80.5692 75.207 80.6187 65.2222 80.7444 59.9092V59.9053Z"
        fill="#D8583A"
      />
      <path
        d="M12.289 45.1112C13.6442 47.2868 12.4755 50.7334 14.516 52.2831C15.2431 52.8365 16.2025 52.9625 17.1085 53.0655C25.9176 54.0923 34.7306 55.119 43.5398 56.1457C46.1589 56.4511 48.8275 56.7488 51.4162 56.264L45.9381 35.8325C34.6278 38.2219 23.3746 41.1188 12.2852 45.1112H12.289Z"
        fill="#E2826B"
      />
      <path
        d="M78.9967 35.0388C78.7683 34.0312 78.536 33.0235 78.3076 32.0159C78.1934 31.5121 78.0564 30.9701 77.6681 30.6266C77.1161 30.138 76.2785 30.2334 75.5514 30.3594C65.6802 32.0502 55.786 33.7487 45.9414 35.8289L51.4195 56.2604C51.4957 56.2452 51.5718 56.2375 51.6479 56.2223C53.913 55.7566 56.003 54.6841 58.0626 53.6268C65.1776 49.9665 72.4717 46.1725 77.7747 40.1725C78.3838 39.4816 78.9814 38.7335 79.1946 37.8327C79.4116 36.9129 79.2022 35.9549 78.9929 35.0312L78.9967 35.0388Z"
        fill="#D8583A"
      />
      <path
        d="M33.468 31.5806C25.7781 34.8936 18.6173 39.4356 12.3359 44.9815C14.7571 46.5769 17.7912 46.8288 20.6806 47.0273C29.9542 47.6609 39.2355 48.1953 48.5167 48.7296C49.5674 48.7907 50.6371 48.8479 51.6612 48.6075C52.7423 48.3518 53.7207 47.7754 54.6724 47.1991C62.3014 42.5883 69.6754 37.5463 76.741 32.1073C77.1674 31.7791 77.6318 31.3783 77.6508 30.8401C77.6813 29.9012 76.4593 29.5348 75.5266 29.4737C66.5195 28.8592 57.4857 28.5805 48.4557 28.6454C42.6959 28.6874 36.9132 30.1035 33.468 31.5882V31.5806Z"
        fill="#E2826B"
      />
      <path
        d="M39.47 30.1226C39.5613 33.7638 36.3711 36.5959 33.219 38.4051C30.0669 40.2143 26.4732 41.7525 24.6193 44.8784C23.047 47.5235 23.0546 50.7831 23.1041 53.8633C23.1422 56.1228 23.1764 58.3824 23.2145 60.642C23.2335 61.9168 23.9226 63.6993 25.118 63.268C25.5672 63.1077 25.8375 62.6573 26.0468 62.2298C26.8387 60.6305 27.7371 58.5733 29.5187 58.558C32.2635 58.5351 33.4208 63.5351 36.0704 62.8138C37.7911 62.3443 38.2632 59.6305 40.0372 59.4244C41.1031 59.2985 41.9787 60.1801 42.8467 60.8176C43.7146 61.455 45.1384 61.7832 45.7056 60.871C45.9721 60.4435 45.9264 59.8977 45.8731 59.3939C44.891 50.0579 44.5788 40.6532 44.9481 31.2714C42.9609 31.2409 40.9813 30.8096 39.4776 30.1264L39.47 30.1226Z"
        fill="#95BCDD"
      />
      <path
        d="M44.9378 33.0693C51.855 36.1685 59.3241 39.8022 62.5181 46.6916C63.9571 49.7985 64.3682 53.3099 65.8263 56.4092C66.9189 55.2489 67.2957 53.6077 68.0381 52.1992C68.7804 50.7908 70.2842 49.4817 71.8107 49.9359C72.8881 50.2565 73.9959 51.3824 74.9095 50.726C75.5224 50.287 75.4806 49.3214 75.9945 48.7717C77.0338 47.6648 79.1694 49.1 80.3229 48.1152C81.1376 47.4168 80.795 46.0999 80.3838 45.1076C79.8052 43.703 79.2265 42.3022 78.6479 40.8976C77.9703 39.2564 77.2432 37.5464 75.8727 36.4281C74.6126 35.4013 72.9642 35.0044 71.3653 34.7258C65.2362 33.6609 58.8254 33.9013 52.9856 31.7486C51.2916 31.1227 49.6051 30.2906 47.7968 30.3059C45.9924 30.3211 44.0204 31.5654 44.934 33.0693H44.9378Z"
        fill="#95BCDD"
      />
      <path
        d="M40.3085 26.0043C32.2836 23.363 24.7764 18.2217 16.3289 18.3591C15.8835 18.3668 15.3962 18.4049 15.0688 18.7141C14.73 19.0347 14.6729 19.5462 14.6348 20.008C14.3151 24.0233 14.7529 32.0005 14.4331 36.0158C14.3988 36.451 14.3798 36.9319 14.6615 37.2639C14.8899 37.5311 15.2592 37.6265 15.6056 37.6952C20.2234 38.6189 24.9858 37.8021 29.6607 37.241C33.3762 36.7945 36.357 32.5501 40.0992 32.4318C40.0497 30.3249 40.2476 28.2104 40.3047 26.0043H40.3085Z"
        fill="#95BCDD"
      />
      <path
        d="M45.4657 25.4088C46.661 27.9088 46.4364 31.0348 44.8984 33.3402C51.9184 28.1302 61.801 33.3593 70.3247 31.4547C70.6445 31.3822 70.9833 31.2982 71.2041 31.0615C71.5505 30.6913 71.4934 30.1073 71.3982 29.6111C70.5036 24.9355 67.6789 15.1224 65.4328 10.9277C65.075 10.2598 64.6753 9.54985 63.9938 9.21778C63.3581 8.90862 62.6043 8.99641 61.9229 9.1758C57.347 10.3896 54.7545 15.0461 51.9069 18.84C49.9806 21.4049 47.7308 23.7218 45.4619 25.4126L45.4657 25.4088Z"
        fill="#95BCDD"
      />
      <path
        d="M42.2777 22.5614C40.7854 22.7255 38.033 25.092 37.6637 26.424C37.2945 27.7561 37.363 29.1721 37.6143 30.5271C37.717 31.0806 37.8769 31.6684 38.3147 32.0233C38.6307 32.2791 39.0418 32.3707 39.434 32.4546C41.2917 32.8554 43.199 33.2562 45.0834 32.989C45.9704 32.863 46.846 32.5806 47.5769 32.0615C49.2862 30.8477 49.8572 28.3744 49.0502 26.4393C47.0477 21.6301 44.2116 22.3515 42.2739 22.5652L42.2777 22.5614Z"
        fill="#325A50"
      />
      <path
        d="M81.3986 30.1298C83.3554 28.1259 85.3083 26.1259 87.2651 24.1221C88.5023 24.9312 89.5987 25.9542 90.4895 27.1336C88.0873 28.1832 86.3704 28.771 84.1091 30.1031C83.6447 30.3779 81.9849 31.1832 81.9849 31.1832C81.9849 31.1832 81.0674 30.4657 81.3948 30.1298H81.3986Z"
        fill="#325A50"
      />
      <path
        d="M83.3979 34.863C87.6236 34.3439 88.069 34.5347 91.8188 33.8706C92.0434 34.9622 92.2718 36.0538 92.4964 37.1416C90.0029 37.4928 88.3964 37.0882 85.9295 36.5882C85.3623 36.4737 83.3789 36.2981 83.3789 36.2981C83.3789 36.2981 83.4436 35.0805 83.3979 34.863Z"
        fill="#325A50"
      />
      <path
        d="M77.5723 15.3589L76.9746 24.504C76.9632 24.7025 76.9556 24.9239 77.0812 25.0804C77.1954 25.2178 77.382 25.2598 77.5533 25.2941C78.2081 25.4277 78.8628 25.5613 79.5176 25.6987C80.6369 22.6032 81.3449 19.3589 81.6076 16.0803C80.161 15.7597 78.7106 15.4581 77.5723 15.3589Z"
        fill="#325A50"
      />
    </svg>
  );
}
