export function Flowers(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 105 104" fill="none" {...props}>
      <path
        d="M74.8897 40.1047C74.8897 40.1047 68.1392 42.2543 61.9553 45.8816L62.9793 49.1603C62.9793 49.1603 71.7274 43.2886 75.9779 42.321C80.2284 41.3533 74.8837 40.1061 74.8837 40.1061L74.8897 40.1047Z"
        fill="#5E9E8B"
      />
      <path
        d="M85.9027 39.8209C85.5678 41.7498 91.5058 41.4085 90.1065 46.4051C88.7146 51.3984 80.1601 50.9428 74.0666 41.275C74.0666 41.275 83.6556 24.9497 89.8823 32.8744C93.101 36.9743 86.2396 37.8561 85.8921 39.8168L85.9027 39.8209Z"
        fill="#D8583A"
      />
      <path
        d="M43.4219 15.7477C43.4219 15.7477 41.9632 36.0935 40.3322 43.0693L37.9954 41.6072C37.9954 41.6072 41.3174 19.7069 40.9801 15.3637C40.6429 11.0206 43.4219 15.7477 43.4219 15.7477Z"
        fill="#5E9E8B"
      />
      <path
        d="M44.1686 2.04128L41.4614 8.39528C41.3003 8.76853 40.8881 8.96399 40.4953 8.84702L35.0119 7.20903C34.349 7.01066 33.768 7.68272 34.0539 8.30985L36.5954 13.8055C36.7334 14.098 36.6773 14.4442 36.4621 14.6805L33.5242 17.8863C33.0243 18.4288 33.4699 19.3053 34.2081 19.2262L42.8641 18.2844C43.121 18.2545 43.3758 18.3521 43.5508 18.544L47.8263 23.2544C48.3565 23.8386 49.3157 23.398 49.2172 22.6202L48.2083 14.7911C48.1687 14.4985 48.296 14.2123 48.5327 14.0409L55.3895 9.13957C56.0735 8.65045 55.6313 7.56995 54.7961 7.69657L48.1412 8.70036C47.7357 8.76092 47.3459 8.50673 47.2398 8.10541L45.6862 2.1562C45.4917 1.4162 44.4689 1.33786 44.1686 2.04128Z"
        fill="#FCD575"
      />
      <path
        d="M69.1875 33.0133C69.1875 33.0133 66.2352 39.7343 61.9577 45.8837L59.9904 44.4931C59.9904 44.4931 65.6629 35.4192 68.1696 31.4129C69.9443 28.5766 69.1852 33.017 69.1852 33.017L69.1875 33.0133Z"
        fill="#5E9E8B"
      />
      <path
        d="M69.0539 28.0475C69.0539 28.0475 67.4646 25.9453 71.2425 20.3129C75.0243 14.6827 75.545 13.5195 78.5534 15.1907C81.5619 16.8618 77.8987 22.7825 74.9323 25.3471C71.9659 27.9117 69.0539 28.0475 69.0539 28.0475Z"
        fill="#95BCDD"
      />
      <path
        d="M68.6512 27.751C68.6512 27.751 69.0779 25.1327 76.2696 23.7177C83.4553 22.3041 84.7443 21.8329 85.7117 25.1277C86.679 28.4225 79.3577 30.1273 75.1479 29.9045C70.9418 29.684 68.6512 27.751 68.6512 27.751Z"
        fill="#95BCDD"
      />
      <path
        d="M67.9042 26.3144C67.9042 26.3144 70.4033 25.4152 75.1895 30.981C79.9756 36.5467 81.0225 37.4388 78.6251 39.8802C76.2253 42.3253 71.1177 36.7851 69.2356 33.0159C67.3512 29.2504 67.908 26.3167 67.908 26.3167L67.9042 26.3144Z"
        fill="#95BCDD"
      />
      <path
        d="M70.6105 25.9425C70.6105 25.9425 72.0249 28.1671 67.7915 33.4683C63.558 38.7694 62.9397 39.8818 60.0795 37.9737C57.2193 36.0656 61.3607 30.4647 64.5259 28.1533C67.6972 25.8405 70.6105 25.9425 70.6105 25.9425Z"
        fill="#95BCDD"
      />
      <path
        d="M70.9877 26.2701C70.9877 26.2701 70.3444 28.8457 63.0611 29.6623C55.7777 30.4789 54.4537 30.8407 53.7641 27.4827C53.0708 24.1223 60.5144 23.0247 64.6899 23.5919C68.8655 24.1591 70.9914 26.2725 70.9914 26.2725L70.9877 26.2701Z"
        fill="#95BCDD"
      />
      <path
        d="M71.6121 27.7632C71.6121 27.7632 69.0458 28.4554 64.7357 22.5177C60.4256 16.58 59.4515 15.6047 62.0463 13.3692C64.6411 11.1337 69.2722 17.0713 70.84 20.9788C72.4077 24.8863 71.6121 27.7632 71.6121 27.7632Z"
        fill="#95BCDD"
      />
      <path
        d="M67.7987 25.2081C68.7532 23.6826 69.7289 23.6954 72.2967 24.1841C74.0693 24.5204 74.2429 27.1227 73.3428 28.6771C72.1105 30.8119 70.374 30.658 68.8447 29.7011C67.0962 28.4988 66.8465 26.7299 67.7987 25.2081Z"
        fill="#FCD575"
      />
      <path
        d="M58.244 20.6988C58.244 20.6988 56.916 25.8084 51.6428 37.7032L49.6213 37.1854C49.6213 37.1854 55.3193 23.4698 57.3273 18.7959C59.0452 14.8009 58.244 20.6988 58.244 20.6988Z"
        fill="#5E9E8B"
      />
      <path
        d="M69.0345 8.99336C70.1127 8.0811 69.8492 6.37565 66.1796 4.71847C62.51 3.06128 61.373 2.98356 60.2304 6.24111C59.0916 9.50098 61.6651 10.2868 61.5243 12.7543C61.3775 15.2231 61.0372 13.2018 59.2239 15.4213C57.4082 17.6445 60.6156 20.9755 62.7079 21.347C64.8002 21.7185 64.5446 18.9412 66.9398 17.3639C69.3327 15.7904 68.8478 13.694 67.4724 11.4319C66.556 9.92594 68.6007 9.35567 69.0354 8.98363L69.0345 8.99336Z"
        fill="#D8583A"
      />
      <path
        d="M61.1468 4.06397C62.8831 2.45573 60.2057 1.2236 57.3943 2.0097C54.5828 2.7958 52.0102 1.64462 50.787 3.68216C49.5639 5.7197 51.294 5.94183 50.0553 7.83051C48.8166 9.71919 48.6441 10.4253 49.4391 12.0923C50.2303 13.757 52.1109 14.4339 53.2839 12.9729C54.4569 11.512 54.5742 11.9203 57.0418 11.4188C59.5058 10.9151 62.0644 10.3675 61.6138 8.51413C61.1633 6.66075 60.5818 4.58633 61.1468 4.06397Z"
        fill="#E2826B"
      />
      <path
        d="M60.6732 15.4784C59.3007 14.9082 60.7267 13.9199 57.9772 13.1423C55.2277 12.3648 56.44 12.165 55.4231 11.1423C54.4061 10.1196 52.0575 10.6543 50.7573 11.9378C49.4609 13.2236 50.6932 14.4068 51.1493 16.748C51.6053 19.0891 53.6328 19.5642 54.8739 20.99C56.115 22.4157 58.7624 22.6862 60.0963 21.9031C61.4303 21.1199 64.6512 17.1225 60.6755 15.4747L60.6732 15.4784Z"
        fill="#D8583A"
      />
      <path
        d="M55.3598 11.4332C55.6567 10.032 55.6092 7.7662 58.1038 8.1318C60.5985 8.4974 60.6046 8.22296 62.6496 8.67821C64.6947 9.13346 65.6006 10.1434 64.8626 11.6704C64.1246 13.1974 63.6508 14.3848 62.3937 14.1598C61.1365 13.9348 59.5602 15.3701 57.8923 14.0637C56.2244 12.7574 55.058 12.8673 55.3622 11.4295L55.3598 11.4332Z"
        fill="#FCD575"
      />
      <path
        d="M60.1552 11.2557C58.7839 11.3869 58.5365 9.34117 57.3983 10.2004C56.26 11.0597 56.2958 11.0357 56.5577 12.3382C56.8196 13.6408 56.7109 14.2117 58.0007 14.4674C59.2904 14.7231 59.8975 14.8865 61.236 14.2865C62.5744 13.6865 62.2178 11.0624 60.1552 11.2557Z"
        fill="#E2826B"
      />
      <path
        d="M74.4844 51.2944C65.6046 48.2577 50.1032 38.1617 40.3553 29.2801C39.3553 28.371 37.7737 28.6231 37.1108 29.7901C33.6788 35.8709 25.2237 53.6372 28.3942 76.5806C28.3942 76.5806 57.5718 77.5048 75.3431 54.1091C76.0955 53.1217 75.6643 51.6926 74.483 51.2884L74.4844 51.2944Z"
        fill="#325A50"
      />
      <path
        d="M35.5334 84.1219C56.3281 75.2823 78.8125 57.6436 78.8125 57.6436C78.8125 57.6436 78.8535 55.7989 77.0012 56.1444C71.4457 57.1869 58.6499 57.62 45.1151 44.4471L27.4929 77.4268C29.0168 79.2613 31.5873 81.8641 35.5334 84.1219Z"
        fill="#5E9E8B"
      />
      <path
        d="M25.9849 79.2498L15.2899 85.552C15.2899 85.552 17.9318 92.7078 22.8908 95.2748C27.8498 97.8419 33.9045 96.0761 33.9045 96.0761L35.2063 85.638C32.6709 84.0052 28.4939 81.2474 25.9886 79.2521L25.9849 79.2498Z"
        fill="#5E9E8B"
      />
      <path
        d="M35.3835 84.1878C35.4327 84.167 35.482 84.1463 35.5312 84.1256C31.5851 81.8677 29.0169 79.2612 27.4907 77.4304L27.4537 77.4897L26.7599 78.7971L25.9801 79.2572C28.4915 81.2511 32.6647 84.0065 35.1978 85.643L35.3812 84.1915L35.3835 84.1878Z"
        fill="#325A50"
      />
    </svg>
  );
}
