export function Cocktails(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 94 85" fill="none" {...props}>
      <g clipPath="url(#clip0_1102_17250)">
        <path
          d="M46.8469 84.2933C72.6938 84.2933 93.6469 77.3089 93.6469 68.6933C93.6469 60.0776 72.6938 53.0933 46.8469 53.0933C21 53.0933 0.046875 60.0776 0.046875 68.6933C0.046875 77.3089 21 84.2933 46.8469 84.2933Z"
          fill="#325A50"
        />
        <path
          d="M41.6952 15.6645C41.4582 16.0104 40.932 15.9219 40.8476 15.5116C40.478 13.774 39.9879 10.5722 40.7994 8.43234C41.9644 5.36332 48.7614 5.69315 47.8214 10.3791C46.9417 14.7635 43.5391 12.9695 41.6952 15.6645Z"
          fill="#DEC4DF"
        />
        <path
          d="M36.9299 9.63126C37.167 9.97717 37.6932 9.88868 37.7776 9.47841C38.1471 7.74077 38.6372 4.53901 37.8258 2.39914C36.6608 -0.669883 29.8637 -0.340053 30.8038 4.34594C31.6835 8.73026 35.0861 6.93631 36.9299 9.63126Z"
          fill="#95BCDD"
        />
        <path
          d="M7.87946 68.1517C7.87946 68.1517 7.88749 68.1517 7.89151 68.1476L7.89954 68.1678C7.9357 68.2804 8.06425 68.4735 8.1245 68.5941C8.50614 69.1814 9.1288 69.7043 9.73941 70.1588C11.3101 71.2167 13.2504 72.0412 15.1907 72.6124C19.9993 74.1248 25.5429 74.8649 30.7412 75.3476C33.2077 75.6251 36.3331 75.7659 38.8277 75.9509C40.1855 75.9952 42.9092 76.0998 44.267 76.1561C56.1176 76.4939 70.9289 76.136 81.0481 70.9512C81.3695 70.7621 82.0163 70.4042 82.3577 70.2111C82.9523 69.8169 83.0808 69.7204 83.5749 69.3946L84.4627 68.6826C85.0452 68.1798 85.5795 67.6167 86.0174 67.0294C86.7646 66.0922 88.6205 66.7841 88.1505 67.854C87.6564 68.9843 86.6682 70.0864 85.7241 71.0115L84.7078 71.8763L84.4507 72.0935C84.298 72.1981 83.7878 72.5682 83.6231 72.6808L83.0648 73.0669C82.9282 73.1715 82.643 73.3485 82.4863 73.433C78.6298 75.7699 74.0221 77.3587 69.4064 78.312C68.8721 78.4166 67.8357 78.6861 67.3255 78.7545C65.2567 79.0964 63.1075 79.4664 61.0025 79.6394C58.2668 79.9612 55.2619 80.102 52.5222 80.2106C50.9274 80.2749 48.5011 80.2106 46.8781 80.2226C45.2753 80.2548 42.8891 80.0939 41.2702 80.0376L38.4742 79.9049L35.6742 79.6756C34.0433 79.5308 31.6852 79.3458 30.0823 79.1004C28.4594 78.9114 26.0933 78.5695 24.5186 78.2517C19.3766 77.3065 14.1382 75.9952 9.8559 73.445C8.83153 72.8538 7.87946 72.1177 7.10414 71.3092C6.3449 70.5369 5.72224 69.5997 5.5254 68.6183L5.49326 68.4252L5.48523 68.3769V68.3085C5.31249 66.957 7.77903 66.7841 7.88347 68.1396L7.87946 68.1517Z"
          fill="#5E9E8B"
        />
        <path
          d="M20.7354 57.0402C20.7354 57.0402 20.0469 61.7751 19.9764 62.3955C19.9764 62.3955 13.4861 61.3306 13.2362 63.5084C12.9863 65.6861 17.9951 65.9459 20.4785 66.2364C22.9619 66.5269 27.2411 67.6451 27.491 65.4674C27.7409 63.2896 22.1121 62.955 22.1121 62.955L22.7173 57.667C22.7173 57.667 21.7111 58.0119 20.7309 57.0367L20.7354 57.0402Z"
          fill="#95BCDD"
        />
        <path
          d="M28.406 22.4644C25.4373 21.6087 20.0479 21.0465 18.8724 24.8356C18.826 24.9871 18.7991 25.1322 18.7797 25.2723C18.3187 28.6466 25.5847 28.9002 27.5694 29.0486C29.6357 29.2033 33.8612 30.3117 35.1334 27.3213C36.4057 24.3308 28.406 22.4644 28.406 22.4644Z"
          fill="#95BCDD"
        />
        <path
          d="M25.4861 37.811C23.5597 35.7391 20.5905 34.8794 18.1686 34.5288C17.8301 40.3152 17.4191 47.1396 17.222 49.7526C16.7517 55.8872 20.2325 57.8963 22.2749 58.0539C24.3173 58.2115 27.7129 56.1791 29.4279 50.2987C30.0771 48.0767 31.0499 44.2514 32.01 40.3547C30.0322 40.3959 27.4597 39.9379 25.4861 37.811Z"
          fill="#95BCDD"
        />
        <path
          d="M35.1369 27.322C33.8646 30.3124 29.6351 29.2045 27.5728 29.0493C25.5921 28.9004 18.3216 28.6433 18.7831 25.273L18.7049 25.2622C18.7049 25.2622 18.4661 29.501 18.1692 34.5287C20.5866 34.8758 23.5598 35.735 25.4867 37.8109C27.4643 39.9374 30.0323 40.3918 32.0106 40.3546C33.5921 33.9394 35.1408 27.3215 35.1408 27.3215L35.1369 27.322Z"
          fill="#FCD575"
        />
        <path
          d="M74.7645 55.7511C74.7645 55.7511 76.0179 60.3687 76.1625 60.9761C76.1625 60.9761 82.4775 59.1379 82.9877 61.2697C83.4979 63.4015 78.5567 64.2623 76.1264 64.8496C73.692 65.4328 69.5824 67.0618 69.0722 64.93C68.562 62.7982 74.1097 61.7886 74.1097 61.7886L72.8724 56.6119C72.8724 56.6119 73.9129 56.8331 74.7686 55.7471L74.7645 55.7511Z"
          fill="#D8583A"
        />
        <path
          d="M62.9902 22.3498C65.8344 21.1431 71.1169 19.9364 72.7399 23.5565C72.8041 23.7013 72.8483 23.842 72.8845 23.9788C73.7482 27.2731 66.5655 28.3993 64.6131 28.7855C62.5805 29.1877 58.5191 30.7966 56.8962 27.981C55.2732 25.1654 62.9902 22.3498 62.9902 22.3498Z"
          fill="#D8583A"
        />
        <path
          d="M67.7407 37.2327C69.4038 34.944 72.2479 33.7333 74.61 33.0938C75.6425 38.7974 76.8717 45.5227 77.3819 48.0929C78.587 54.1264 75.3733 56.5398 73.3647 56.942C71.3561 57.3443 67.7407 55.7353 65.3304 50.1041C64.4185 47.9763 62.9924 44.2959 61.5703 40.5431C63.5387 40.346 66.0374 39.5817 67.7407 37.2327Z"
          fill="#D8583A"
        />
        <path
          d="M56.8946 27.9813C58.5176 30.7969 62.583 29.188 64.6116 28.7858C66.5599 28.3996 73.7467 27.2693 72.883 23.9791L72.9593 23.959C72.9593 23.959 73.7065 28.1382 74.6063 33.0936C72.2483 33.7292 69.4001 34.9399 67.737 37.2326C66.0297 39.5816 63.535 40.3419 61.5666 40.543C59.2246 34.3647 56.8906 27.9813 56.8906 27.9813H56.8946Z"
          fill="#FCD575"
        />
        <path
          d="M46.1291 61.0928C46.1291 61.0928 45.7515 66.724 45.6792 67.4561C45.6792 67.4561 53.4243 67.8422 53.1712 70.4165C52.9181 72.9908 47.0812 72.0455 44.1446 71.7599C41.2081 71.4744 35.9978 71.7036 36.2469 69.1293C36.5 66.5551 43.068 67.5727 43.068 67.5727L43.6787 61.3261C43.6787 61.3261 44.7512 61.9737 46.1211 61.0928H46.1291Z"
          fill="#DEC4DF"
        />
        <path
          d="M45.8488 19.321C49.4844 19.0716 55.8355 19.7634 56.2533 24.4253C56.2694 24.6103 56.2653 24.7833 56.2533 24.9522C55.952 28.9544 47.5079 27.442 45.186 27.1202C42.7677 26.7864 37.6217 27.0116 36.8905 23.2467C36.1634 19.4819 45.8488 19.321 45.8488 19.321Z"
          fill="#DEC4DF"
        />
        <path
          d="M45.4204 37.743C48.1561 35.8324 51.7916 35.579 54.6679 35.7801C53.6234 42.5335 52.4062 50.5098 51.9924 53.5667C51.0163 60.7586 46.505 62.2067 44.1108 61.8808C41.7165 61.555 38.306 58.3653 37.7837 51.1614C37.5869 48.4383 37.4101 43.7845 37.2695 39.0502C39.5392 39.5892 42.6164 39.7018 45.4204 37.743Z"
          fill="#DEC4DF"
        />
        <path
          d="M36.8945 23.2466C37.6216 27.0115 42.7716 26.7862 45.19 27.1201C47.5119 27.4419 55.956 28.9542 56.2573 24.952L56.3497 24.9601C56.3497 24.9601 55.5744 29.9075 54.6705 35.7801C51.7942 35.579 48.1587 35.8324 45.423 37.743C42.619 39.7019 39.5418 39.5892 37.2721 39.0502C37.0392 31.259 36.8945 23.2466 36.8945 23.2466Z"
          fill="#FCD575"
        />
        <path
          d="M72.2604 17.1609C73.4294 14.4499 74.5984 11.7389 75.7714 9.02783C77.294 9.35364 78.7602 9.94492 80.0859 10.7655C78.1175 12.7082 76.6472 13.9391 74.9238 16.1031C74.5703 16.5455 73.2406 17.9734 73.2406 17.9734C73.2406 17.9734 72.0676 17.6155 72.2645 17.1609H72.2604Z"
          fill="#325A50"
        />
        <path
          d="M76.0156 21.0587C79.9806 18.947 80.4948 18.9671 83.9335 16.8916C84.5682 17.8771 85.2029 18.8666 85.8376 19.852C83.5157 21.1432 81.7762 21.3564 79.1611 21.7948C78.5585 21.8954 76.5379 22.4786 76.5379 22.4786C76.5379 22.4786 76.1402 21.2558 76.0156 21.0587Z"
          fill="#325A50"
        />
        <path
          d="M62.9141 4.07641C63.8701 7.14946 64.8222 10.2265 65.7783 13.2996C65.8426 13.5007 65.9189 13.7219 66.1037 13.8225C66.2684 13.915 66.4693 13.8868 66.65 13.8546C67.345 13.738 68.04 13.6254 68.7349 13.5087C68.6707 10.0415 68.1404 6.58231 67.1642 3.25586C65.6176 3.48915 64.075 3.74256 62.9141 4.07641Z"
          fill="#325A50"
        />
      </g>
      <defs>
        <clipPath id="clip0_1102_17250">
          <rect
            width="93.6"
            height="83.8933"
            fill="white"
            transform="translate(0.046875 0.399902)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
