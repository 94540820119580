export function Phone(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 105 104" fill="none" {...props}>
      <path
        d="M52.7776 48.7617H51.0481C49.671 48.7617 48.5547 49.8794 48.5547 51.2582V60.8348C48.5547 62.2136 49.671 63.3313 51.0481 63.3313H52.7776C54.1547 63.3313 55.271 62.2136 55.271 60.8348V51.2582C55.271 49.8794 54.1547 48.7617 52.7776 48.7617Z"
        fill="#95BCDD"
      />
      <path
        d="M74.887 49.0381H73.1574C71.7804 49.0381 70.6641 50.1558 70.6641 51.5346V61.1111C70.6641 62.4899 71.7804 63.6077 73.1574 63.6077H74.887C76.2641 63.6077 77.3804 62.4899 77.3804 61.1111V51.5346C77.3804 50.1558 76.2641 49.0381 74.887 49.0381Z"
        fill="#95BCDD"
      />
      <path
        d="M62.6912 5.32919C56.108 7.93425 34.1457 19.8988 26.8676 28.8684C19.5896 37.8381 26.3059 42.8805 27.4294 44.558C28.5529 46.2355 31.3468 46.7979 38.6249 42.318C45.9029 37.8332 47.5783 36.1557 47.0215 35.0308C46.4647 33.9059 45.0603 32.5096 43.9418 29.7072C42.8232 26.9048 41.1429 25.7848 45.0603 23.5449C48.9778 21.3049 51.7766 19.6225 54.0138 18.5025C56.2509 17.3825 58.4929 17.3825 59.6115 19.6225C60.7301 21.8624 59.1385 19.2327 61.2918 23.5449C61.8536 24.6648 65.224 26.5989 71.9256 22.9824C79.2036 19.06 79.6372 18.4186 79.0804 15.0587C78.5187 11.6938 74.7244 0.568058 62.6912 5.32919Z"
        fill="#CEE3F4"
      />
      <path
        d="M29.1127 86.249C25.1706 86.249 21.7558 84.6751 19.6418 82.5338C16.5719 79.4255 16.0053 75.138 18.1586 71.3439C17.5919 70.5891 17.0351 69.7355 16.4931 68.7833C14.2018 64.7672 14.7635 60.6968 16.7198 57.7513C16.5029 57.1296 16.3502 56.4537 16.2516 55.7383C14.9803 46.1765 23.9929 40.5273 24.3772 40.2954L26.4222 43.6405L25.3973 41.968L26.4222 43.6356C26.3532 43.68 19.6418 47.928 20.0607 54.5591C22.8053 52.9704 25.7225 53.2368 27.5063 55.3189C28.7874 56.8138 29.019 58.6196 28.1222 60.1442C27.0135 62.0338 24.3772 63.0354 21.8445 62.5223C20.8737 62.3249 20.0015 61.9302 19.2427 61.3678C18.6169 63.3659 19.1146 65.4826 19.8783 66.8295C20.1395 67.2883 20.3957 67.7077 20.647 68.0827C22.554 66.3312 24.3772 66.0549 25.481 66.0944C27.4767 66.1684 29.29 67.3229 30.108 69.0349C30.7437 70.367 30.6353 71.8126 29.8222 72.903C28.4671 74.7088 26.5306 75.6709 24.5152 75.5476C23.3424 75.4735 22.1992 75.0492 21.0856 74.2845C20.3908 76.2284 20.8491 78.1822 22.4161 79.7709C24.8601 82.2477 29.8863 83.5847 35.1391 80.6639L37.0412 84.0929C34.3113 85.6125 31.6011 86.2441 29.0979 86.2441L29.1127 86.249ZM23.2735 71.0183C23.9633 71.5067 24.4807 71.6202 24.7714 71.64C25.5993 71.6794 26.2547 71.0972 26.5749 70.7124C26.4665 70.5052 26.1315 70.1549 25.5894 70.0513C24.8749 69.9181 24.0668 70.2634 23.2685 71.0183H23.2735ZM21.6079 58.2397C21.9529 58.4963 22.3569 58.6591 22.8053 58.7183C23.8106 58.8564 24.599 58.4272 24.7468 58.1904C24.7271 58.1361 24.668 58.0276 24.5448 57.8845C23.6578 56.8484 21.8346 58.0818 21.6276 58.2249C21.6227 58.2249 21.6129 58.2348 21.6079 58.2397Z"
        fill="#5E9E8B"
      />
      <path
        d="M49.3102 87.2557L43.9787 87.2967C42.7759 87.306 41.8083 88.2898 41.8175 89.4942L41.843 92.8194C41.8522 94.0238 42.8348 94.9926 44.0377 94.9834L49.3692 94.9424C50.572 94.9331 51.5396 93.9493 51.5304 92.745L51.5049 89.4197C51.4956 88.2153 50.5131 87.2465 49.3102 87.2557Z"
        fill="#95BCDD"
      />
      <path
        d="M81.1891 87.1581L75.8576 87.1991C74.6548 87.2083 73.6872 88.1921 73.6964 89.3965L73.7219 92.7218C73.7312 93.9262 74.7137 94.895 75.9166 94.8857L81.2481 94.8447C82.4509 94.8355 83.4185 93.8517 83.4093 92.6473L83.3838 89.322C83.3745 88.1177 82.392 87.1488 81.1891 87.1581Z"
        fill="#95BCDD"
      />
      <path
        d="M80.0805 57.1641H45.8534C39.9261 57.1641 35.1211 61.9752 35.1211 67.9099V78.9222C35.1211 84.857 39.9261 89.6681 45.8534 89.6681H80.0805C86.0078 89.6681 90.8128 84.857 90.8128 78.9222V67.9099C90.8128 61.9752 86.0078 57.1641 80.0805 57.1641Z"
        fill="#CEE3F4"
      />
      <path
        d="M47.2995 70.6134C46.3435 61.999 54.0158 55.4814 62.9692 54.9239C71.9227 54.3664 79.2155 61.9694 78.639 70.6134C78.0772 79.0206 71.6221 86.303 62.9692 86.303C54.3164 86.303 48.418 80.6981 47.2995 70.6134Z"
        fill="#5E9E8B"
      />
      <path
        d="M51.7734 65.0138C51.7734 63.4646 52.3352 62.2114 54.5723 62.2114C56.1196 62.2114 57.3712 63.4646 57.3712 65.0138C57.3712 66.5631 56.0703 67.4413 54.5723 67.8162C52.3352 68.3787 51.7734 66.5631 51.7734 65.0138Z"
        fill="#CEE3F4"
      />
      <path
        d="M58.7969 68.9114C58.7969 66.6023 59.6296 64.7324 62.9705 64.7324C65.2767 64.7324 67.1442 66.6023 67.1442 68.9114C67.1442 71.2204 65.2077 72.5279 62.9705 73.0903C59.6346 73.9241 58.7969 71.2204 58.7969 68.9114Z"
        fill="#325A50"
      />
      <path
        d="M68.0078 65.0138C68.0078 63.4646 68.5696 62.2114 70.8067 62.2114C72.354 62.2114 73.6056 63.4646 73.6056 65.0138C73.6056 66.5631 72.3047 67.4413 70.8067 67.8162C68.5696 68.3787 68.0078 66.5631 68.0078 65.0138Z"
        fill="#CEE3F4"
      />
      <path
        d="M56.5601 72.8634C57.2302 74.2597 57.2697 75.6313 55.2543 76.6033C53.8598 77.2743 52.1893 76.6872 51.5192 75.2958C50.849 73.9045 51.6374 72.5428 52.825 71.556C54.5989 70.0808 55.8899 71.4721 56.5601 72.8634Z"
        fill="#CEE3F4"
      />
      <path
        d="M73.3531 72.8634C74.0232 74.2597 74.0626 75.6313 72.0472 76.6033C70.6527 77.2743 68.9823 76.6872 68.3121 75.2958C67.642 73.8995 68.4304 72.5428 69.6179 71.556C71.3919 70.0808 72.6829 71.4721 73.3531 72.8634Z"
        fill="#CEE3F4"
      />
      <path
        d="M59.6133 80.1408C59.6133 78.5916 60.175 77.3384 62.4122 77.3384C63.9594 77.3384 65.211 78.5916 65.211 80.1408C65.211 81.69 63.9101 82.5682 62.4122 82.9432C60.175 83.5056 59.6133 81.69 59.6133 80.1408Z"
        fill="#CEE3F4"
      />
      <path
        d="M61.0515 57.0061C62.3918 56.2315 63.7518 56.0884 64.8753 58.0274C65.6489 59.3694 65.1906 61.0814 63.8553 61.856C62.5199 62.6307 61.1057 61.9449 60.0314 60.8347C58.425 59.177 59.7161 57.7807 61.0515 57.0061Z"
        fill="#CEE3F4"
      />
      <path
        d="M26.7114 45.3921C28.8105 49.3688 34.9651 50.2322 40.4545 47.3262C45.9438 44.4202 48.6934 38.84 46.5942 34.8634C44.4951 30.8867 39.2373 31.8834 33.7431 34.7894C28.2537 37.6954 24.6122 41.4155 26.7114 45.3921Z"
        fill="#95BCDD"
      />
      <path
        d="M61.7001 24.1765C63.7992 28.1532 68.6628 28.3258 74.1521 25.4149C79.6415 22.5089 81.1789 19.0305 79.0797 15.0539C76.9806 11.0772 72.7428 11.6545 67.2535 14.5605C61.7641 17.4665 59.6009 20.1998 61.7001 24.1765Z"
        fill="#95BCDD"
      />
    </svg>
  );
}
