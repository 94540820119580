export function House(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 47 64" fill="none" {...props}>
      <g clipPath="url(#clip0_1102_17139)">
        <path
          d="M26.9362 16.7178C26.7235 16.4703 19.7728 16.6023 19.7728 16.6023L7.61243 39.1983C7.30482 39.5712 7.38008 40.1455 7.81859 40.4195L11.6539 40.875L11.9353 40.5515V58.1437L35.8765 60.0086L42.212 56.8136L41.8717 39.6207C41.9764 39.6933 42.1073 39.7264 42.2382 39.7066L46.7018 38.4391L26.9362 16.7178Z"
          fill="#D8583A"
        />
        <path d="M32.0002 13.2883H28.6328V22.9129H32.0002V13.2883Z" fill="#D8583A" />
        <path
          d="M19.9461 21.0613L5.07288 38.6106L4.46094 59.7378L35.6146 60.0051L35.0092 37.8185L19.9461 21.0613Z"
          fill="white"
        />
        <path
          d="M4.79058 40.8748C4.79058 40.8748 16.2965 27.5701 19.9453 23.0416L35.1 39.7427L38.3266 39.3103L39.0302 38.6106L19.7718 16.6055L0.300781 39.7427L4.79058 40.8748Z"
          fill="#E2826B"
        />
        <path
          d="M31.175 11.9944L30.6121 13.4104H30.0493C30.0493 13.4104 29.7678 11.43 29.2083 11.1462C28.6487 10.8623 25.2781 10.2979 24.9999 5.76947C24.7217 1.24104 29.4897 -0.462074 33.9795 0.10563C38.4693 0.673334 42.6253 3.1884 42.3995 7.74983C42.1181 13.4104 31.175 11.9944 31.175 11.9944Z"
          fill="white"
        />
        <path
          d="M19.2689 39.7624L19.5536 34.5574L21.9916 34.7389L21.1669 39.9473L19.2656 39.7657L19.2689 39.7624Z"
          fill="#95BCDD"
        />
        <path
          d="M25.293 40.4852L26.6576 41.0991L29.0596 37.8117L27.1583 36.7258L25.293 40.4852Z"
          fill="#95BCDD"
        />
        <path
          d="M27.2242 45.007L26.6875 43.9211L30.5948 42.0034L31.3867 43.9871L27.2242 45.0037V45.007Z"
          fill="#95BCDD"
        />
        <path
          d="M15 42.294L24.8926 42.0762L25.2853 60.1998L15.5367 60.3714L15 42.294Z"
          fill="#FCD575"
        />
        <path
          d="M14.7266 42.0498L15.2305 60.4837L23.4673 63.2001L23.4934 43.2941L14.7266 42.0498Z"
          fill="#E2826B"
        />
        <path
          d="M37.4624 6.18522C37.1712 6.11591 36.569 6.0928 36.6279 7.33713C36.6803 8.47254 36.569 9.28779 36.2811 9.40331C35.728 9.62445 35.98 9.44952 34.6808 9.49903C33.9773 9.52543 33.7351 10.7731 34.8706 10.7269C35.692 10.6971 36.196 10.7037 36.5396 10.7269C36.8865 10.75 36.6869 12.1329 36.6312 13.3277C36.5756 14.5258 37.8682 14.1331 37.9042 13.6908C37.9435 13.2485 37.9795 11.014 38.2642 10.9942C38.8728 10.9546 39.2034 10.9876 39.9855 10.7269C40.4567 10.5717 40.7545 10.344 40.6465 10.0073C40.5745 9.78288 40.3389 9.67066 40.0247 9.62775C39.0856 9.49243 38.2249 9.60135 38.1365 9.23498C37.9631 8.54515 38.1431 8.05006 38.0842 7.46915C38.0122 6.77933 37.8486 6.27433 37.469 6.18522H37.4657H37.4624Z"
          fill="#95BCDD"
        />
        <path
          d="M41.2792 3.19159C41.9435 3.63388 42.7976 1.36966 44.4306 2.71301C46.0635 4.05305 44.4797 7.15892 39.8655 7.83225C39.8655 7.83225 35.3593 1.5776 39.3452 0.567613C41.4068 0.0461174 40.6018 2.74271 41.2759 3.1949L41.2792 3.19159Z"
          fill="#D8583A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1102_17139">
          <rect width="46.4" height="63.2" fill="white" transform="translate(0.296875)" />
        </clipPath>
      </defs>
    </svg>
  );
}
